import React from 'react'
import { Link } from 'gatsby'
import { Pane, Paragraph } from '@muhsis/ui'
import { Grid } from 'react-styled-flexboxgrid'

import { SpotlightLight } from '../blocks'
import { Layout, SEO, BackgroundImageGrid } from '../components'
import { Header, Footer } from '../blocks'

const PrivacyPage = () => (
  <Layout>
    <SEO
      title="Gizlilik Politikası"
      keywords={[
        `muhsis gizlilik politikası`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Grid>
        <Header />
        <SpotlightLight title="Gizlilik Politikası" />
        <Pane
          maxWidth={700}
          marginX="auto"
          marginTop="3rem"
          marginBottom="5rem"
        >
          <Paragraph marginBottom="1rem">
            İşbu Gizlilik Politikası’nın amacı, www.muhsis.com adresinde yer
            alan siteye (“Site”) üye olarak kaydolan kişiler (“Kullanıcı(lar)”)
            tarafından Site’de yer alan Platform’dan faydalanılması aşamasında
            Muhsis Bilgi Teknoloji ve Hizmetleri A.Ş. (“Şirket”) ile paylaşılan
            bilgi ve verilerin kullanımına ilişkin koşul ve şartları tespit
            etmektir. Gizlilik Politikası, Kullanıcı ile akdedilen{' '}
            <Link to="/kullanici-sozlesmesi">Kullanıcı Sözleşmesi’nin</Link> eki
            ve ayrılmaz bir parçası niteliğindedir.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Kullanıcı tarafından Site’ye yüklenen İçerik Kullanıcı’nın
            mülkiyetindedir. Şirket, bu İçerik’i Kullanıcı’nın önceden onayını
            almaksızın açıklamayacak veya satmayacaktır.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Şirket, Site üzerinden kendisine elektronik ortamdan iletilen
            İçerikleri’i Kullanıcılar ile yaptığı Kullanıcı Sözleşmesi ile
            belirlenen amaçlar ve kapsam dışında üçüncü kişilere
            açıklamayacaktır. Bu kapsamda Şirket, İçerikler’i kesinlikle özel ve
            gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve
            gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya
            herhangi bir kısmının kamu alanına girmesini veya yetkisiz
            kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm
            tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Şirket, Kullanıcı’nın Site üzerinde gerçekleştirdiği kullanım ve
            işlem bilgilerini anonim hale getirerek; istatistiki
            değerlendirmelerde, performans değerlendirmelerinde, yıllık rapor ve
            benzeri raporlarda kullanmak üzere bu amaçların gerçekleştirilmesi
            için gereken sürede saklayabilir, işleyebilir.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Şirket ayrıca, aşağıdaki koşulların varlığı halinde Kullanıcı’ya ait
            bilgileri üçüncü kişilerle paylaşabilecektir:
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Kullanıcı Sözleşmesi kapsamındaki yükümlülüklerin yerine getirilmesi
            için ilgili ifşanın zorunlu olması, Yetkili idari ve adli bir kurum
            tarafından ilgili mevzuat doğrultusunda usulüne göre yürütülen bir
            araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili
            bilgi talep edilmesi, Kullanıcıların hakları veya güvenliklerini
            korumak için bilgi vermenin gerekli olması. Sistemle ilgili teknik
            sorunların tanımlanması ve çözülmesi için, Şirket gereken hallerde
            kullanıcıların IP adresini tespit etmek ve kullanmak durumunda
            kalabilir. IP adresleri, ayrıca kullanıcıları genel bir şekilde
            tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da
            kullanılabilir.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Şirket, yukarıda anılan amaçlarla ilgili verileri Kullanıcı’nın
            ikamet ettiği ülke dışında dünyanın herhangi bir yerinde bulunan
            sunucularına (sunucular kendisine veya alt sağlayıcılarına ait
            olabilir) aktarma hakkına sahiptir.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Site üzerinden başka site ve uygulamalara link verilmesi mümkün
            olup, Şirket’in bu site ve uygulamaların gizlilik uygulamaları ve
            içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.
          </Paragraph>
          <Paragraph marginBottom="1rem">
            Şirket, işbu Gizlilik Politikası hükümlerini dilediği zaman Site’de
            yayımlamak suretiyle değiştirebilir. Şirket’in değişiklik yaptığı
            Gizlilik Politikası hükümleri Site’de yayınlandığı tarihte yürürlük
            kazanır.
          </Paragraph>
        </Pane>
      </Grid>
    </BackgroundImageGrid>
    <Footer />
  </Layout>
)
export default PrivacyPage
